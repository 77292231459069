<template>
  <div class="about">
    <div class="about__left">
      <h1 class="about__left-title">{{ $t('about-app') }}</h1>
      <div class="about__left-links">
        <a href="https://apps.apple.com/ru/app/ecophone/id6446772826?l=en" target="_blank" class="about__left-links-qr">
          <p class="text">{{ $t('available-on-app-store') }}</p>
          <div class="image">
            <img src="../assets/svg/about-qr.svg" alt="" />
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=apro.eco.phone" target="_blank" class="about__left-links-qr">
          <p class="text">{{ $t('download-on-google-play') }}</p>
          <div class="image">
            <img src="../assets/svg/about-qr.svg" alt="" />
          </div>
        </a>
      </div>
    </div>
    <div class="about__content">
      <img src="../assets/svg/logo/with-text.svg" alt="" class="about__content-logo" />
      <div class="about__content-files">
        <AboutItem
            v-for="(item, key) in getDocuments"
            :key="key"
            :data="item"
            v-show="!visibleLoader && (item.type === 'Веб версия' || item.type === 'Ссылка веб')"
        />
      </div>
    </div>
    <div class="about__links">
      <a href="https://apps.apple.com/ru/app/ecophone/id6446772826?l=en" target="_blank" class="about__links-qr">
        <p class="about__links-qr-text">{{ $t('available-on-app-store') }}</p>
        <div class="about__links-qr-image"><img src="../assets/svg/about-qr.svg" alt="" /></div>
      </a>
      <a href="https://play.google.com/store/apps/details?id=apro.eco.phone" target="_blank" class="about__links-qr">
        <p class="about__links-qr-text">{{ $t('download-on-google-play') }}</p>
        <div class="about__links-qr-image"><img src="../assets/svg/about-qr.svg" alt="" /></div>
      </a>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AboutItem from "@/components/template/admin/AboutItem.vue";

export default {
  name: "About",
  components: {AboutItem},

  data () {
    return {
      visibleLoader: false
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getDocuments']),
  },

  methods: {
    ...mapActions(['documents'])
  },

  mounted() {
    this.documents().then(() => {
      this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  justify-content: space-between;
  padding: 150px 163px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;
  gap: 10px;

  @media(max-width: 900px) {
    padding: 150px 50px 56px 50px;
  }
  @media(max-width: 768px) {
    padding: 104px 20px 56px 20px;
    flex-direction: column;
    gap: 30px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #343432;
    }

    &-links {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-qr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 251px;
        width: 100%;
        gap: 3px;
        background-color: #E21F1F;
        border-radius: 200px;
        padding-right: 4px;

        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 120.2%;
          color: #FFFFFF;
          padding: 17px 0 16px 17px;
        }

        .image {
          padding: 10px;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-color: #FFFFFF;
        }
      }

      @media(max-width: 700px) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 40px 46px 40px;
    background: #FFFFFF;
    max-width: 774px;
    width: 100%;
    border-radius: 20px;

    @media (max-width: 700px) {
      padding: 30px 12px;
    }

    &-logo {
      width: 125px;
      height: 38px;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 120.2%;
      color: #343432;
    }

    &-files {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 700px) {
        gap: 30px;
      }

      &-file {
        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 700px) {
          color: #CE2121;
        }

        .image {
          @media (max-width: 700px) {
            display: none;
          }
        }
      }

      &-add {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #CE2121;
        font-size: 14px;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  &__links {
    display: none;
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;

    &-qr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #E21F1F;
      border-radius: 200px;
      padding-right: 4px;

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120.2%;
        color: #FFFFFF;
        padding: 17px 0 16px 20px;
      }

      &-image {
        padding: 10px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #FFFFFF;
      }
    }

    @media (max-width: 700px) {
      display: flex;
    }
  }

}
</style>
